/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../molecules/NavBar';
import UserBasicInfo from '../../molecules/UserBasicInfo';
import BOButton from '../../atoms/BOButton';
import { BiPencil } from 'react-icons/bi';
import { TiPower } from 'react-icons/ti';
import navigation from '../../../utilities/navigation';
import { useSelector, useDispatch } from 'react-redux';
import { loggout } from '../../../redux/ducks/userSlice';
import './index.scss';

const Menu = ({ hiddeMenu }) => {
  const userData = useSelector((state) => state.userSlice.value);
  const dispatch = useDispatch();
  return (
    <div className="menu_container bg-color-primary">
      <div className="menu_section">
        <div className="menu_item">
          <img className="img_logo" src={require('./images/LOGO.png')}></img>
        </div>
        <div className="menu_item">
          <UserBasicInfo
            names={userData?.names}
            email={userData?.email}
            profile={userData?.avatar_128}
          />
        </div>
        <div className="menu_item">
          <Link
            to={navigation.profile.init}
            style={{ textDecoration: 'none' }}
            onClick={() => hiddeMenu()}>
            <BOButton
              style={{ marginTop: '20px', padding: '7px 25px' }}
              className="text-color-dark border-radius-20 bg-color-secondary"
              icon={<BiPencil size={20} style={{ marginTop: '8px' }} />}>
              Ver Perfil
            </BOButton>
          </Link>
        </div>

        <div className="nav_content">
          <p className="my_panel_text text-color-light nav_title">Mi panel</p>
          <NavBar hiddeMenu={hiddeMenu} />
        </div>
        <div>
          <BOButton
            onClick={() => {
              dispatch(loggout({ user: null }));
              window.location.href = process.env.REACT_APP_LOGOUT;
            }}
            // onClick={() => hiddeMenu()}
            className="btn_logout text-color-secondary bo_button_logout"
            icon={<TiPower size={30} style={{ marginTop: '8px' }} />}>
            Cerrar Sesión
          </BOButton>
        </div>
      </div>
    </div>
  );
};

export default Menu;
