import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const programsOnlineSlice = createSlice({
  name: 'programsOnline',
  initialState: {
    value: { data: [] }
  },
  reducers: {
    setProgramsOnline: (state, action) => {
      state.value['data'] = action.payload.data.map((el) => ({ ...el, show: true }));
    },
    searchProgramsOnline: (state, action) => {
      var data = _.clone(state.value.data);
      var show;
      data = data.map((el) => {
        show = true;
        if (
          el.name.toLowerCase().indexOf(action.payload.keyword.toLowerCase()) == -1 &&
          el.name.length >= 3
        ) {
          show = false;
        }
        return { ...el, show };
      });
      state.value['data'] = data;
    }
  }
});

export const { setProgramsOnline, getProgramsOnline, searchProgramsOnline } =
  programsOnlineSlice.actions;

export default programsOnlineSlice.reducer;
