export const youtubeParser = (url) => {
  // const urlId = '';
  // const indexOfV = url.indexOf('v=');
  if (url.indexOf('https://youtu.be/') == 0) {
    return url.replace('https://youtu.be/', '').substr(0, 11);
  }
  if (url.indexOf('https://www.youtube.com/embed/') == 0) {
    return url.replace('https://www.youtube.com/embed/', '').substr(0, 11);
  }
  if (url.indexOf('v=') >= 0) {
    return url.split('v=')[1].split('&')[0];
  }

  // return urlId;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toImage = (file) =>
  new Promise((resolve, reject) => {
    var url = URL.createObjectURL(file);
    var img = new Image();

    img.onload = function () {
      URL.revokeObjectURL(img.src);
      resolve(img);
    };
    img.onerror = (error) => reject(error);

    img.src = url;
  });
