import React from 'react';
import UserProfileTemplate from '../../components/templates/UserProfileTemplate';
import { useSelector } from 'react-redux';

const UserProfilePage = () => {
  const userData = useSelector((state) => state.userSlice.value);

  return <UserProfileTemplate data={userData} />;
};

export default UserProfilePage;
