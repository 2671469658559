/* eslint-disable react/prop-types */
import React from 'react';

import './index.scss';

const TitleAndText = ({ title, description }) => {
  return (
    <div className="title_and_text__container">
      {title && <p className="course_about">{title}</p>}
      <div className="border_region"></div>
      <span className="course_description">{description}</span>
    </div>
  );
};

export default TitleAndText;
