import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import counterSlice from './ducks/counterSlice';
import userSlice from './ducks/userSlice';
import coursesSlice from './ducks/coursesSlice';
import programsZoomSlice from './ducks/programZoomSlice';
import programsOnlineSlice from './ducks/programOnlineSlice';
import certificatesSlice from './ducks/certificatesSlice';

const persistConfig = {
  key: 'weeducacion',
  storage
};

const reducers = combineReducers({
  userSlice,
  counterSlice,
  coursesSlice,
  programsZoomSlice,
  programsOnlineSlice,
  certificatesSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
