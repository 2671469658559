import Api from './Api';
import { BASE_PATH } from './Urls';

export default class CoursesService {
  static async fetchListProgramsZoom() {
    try {
      const resp = await Api.get(`${BASE_PATH}v1/users/me/courses/programas_zoom`);
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
    }
  }

  static async fetchProgramsZoom(id) {
    try {
      const resp = await Api.get(`${BASE_PATH}v1/users/me/courses/programas_zoom/${id}`);
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: {} };
    }
  }

  static async fetchListProgramsOnline() {
    try {
      const resp = await Api.get(`${BASE_PATH}v1/users/me/courses/programas_online`);
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: [] };
    }
  }

  static async fetchProgramsOnline(id) {
    try {
      const resp = await Api.get(`${BASE_PATH}v1/users/me/courses/programas_online/${id}`);
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: {} };
    }
  }

  static async createPresentation({ project_id, student_id }) {
    try {
      const resp = await Api.postWithHeaders(`${BASE_PATH}v1/users/me/presentation/create`, {
        project_id,
        student_id
      });
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: [] };
    }
  }

  static async updateMessagePresentation({ presentation_id, message }) {
    try {
      const resp = await Api.putWithHeaders(`${BASE_PATH}v1/users/me/presentation/update_message`, {
        presentation_id,
        message
      });
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: [] };
    }
  }

  static async uploadFilePresentation({ presentation_id, file, filename, mimetype }) {
    try {
      const resp = await Api.putWithHeaders(`${BASE_PATH}v1/users/me/presentation/upload_file`, {
        presentation_id,
        file,
        filename,
        mimetype
      });
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: [] };
    }
  }

  static async deleteFilePresentation({ presentation_id, attachment_id }) {
    try {
      const resp = await Api.deleteWithHeaders(`${BASE_PATH}v1/users/me/presentation/delete_file`, {
        presentation_id,
        attachment_id
      });
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
      return { data: [] };
    }
  }

  static async fetchListCertificates() {
    try {
      const resp = await Api.get(`${BASE_PATH}v1/users/me/certificates`);
      const rspJson = await resp.json();
      return rspJson;
    } catch (error) {
      console.log('error', error);
    }
  }
}
