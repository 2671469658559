/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import Menu from '../../organisms/Menu';
import { Outlet } from 'react-router-dom';

import './index.scss';
// import ContentActions from '../../organisms/ContentActions';

const HomeTemplate = ({ data }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="home_container">
      <div className="menu_toggle-button">
        <div className="left_menu">
          <button
            className={showMenu ? 'show_menu' : 'show_close'}
            onClick={() => setShowMenu(!showMenu)}>
            <div></div>
            <div></div>
            <div></div>
          </button>
          <div className="logo">
            <img className="img_logo" src={require('../../../assets/LOGO.png')}></img>
            {/* <img src={require('../../../assets/global/logo_cortado.png')} /> */}
          </div>
        </div>
        {/* <div className="right_menu">
          <div>
            <ContentActions search={false} />
          </div>
          <div className="user_options">
            <img src={require('../../../assets/perfil/perfil.png')} />
            <MdOutlineKeyboardArrowDown color="white" size={25} />
          </div>
        </div> */}
      </div>
      <div style={{ display: 'flex' }}>
        <div className={showMenu ? 'menu show_menu' : 'menu hidden_menu'}>
          <Menu data={data} hiddeMenu={() => setShowMenu(false)} />
        </div>
        {/* <div>
          <img src={require('../../../assets/global/portada-inicio.jpg')} width={'100%'} />
        </div> */}
        <div className="outlet" onClick={() => setShowMenu(false)}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HomeTemplate;
