/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import './index.scss';

const BOSelect = ({ icon, label, type, placeholder, value, className, options, ...props }) => {
  return (
    <div className={`boinput_container bg-color-areas border-radius-20 ${className}`} {...props}>
      {label && <label>{label}</label>}
      <div className="input_icon">
        {icon && <div className="icon">{icon}</div>}
        <select className="input" type={type} placeholder={placeholder} value={value} {...props}>
          <option key={0} value={''}>
            {'Seleccionar'}
          </option>
          {_.map(options, (op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BOSelect;
