/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import ZoomProgramsTemplate from '../../components/templates/ZoomProgramsTemplate';
import { useSelector } from 'react-redux';

const OnlineProgramsPage = ({ type }) => {
  const programs = useSelector((state) => state?.programsOnlineSlice?.value?.data);
  // console.log(programs);
  // var title = type == 'CourseOffline' ? 'Cursos en línea' : 'Programas Zoom';
  // const newPrograms = programs.filter((e) => e.type == type);

  return (
    <>
      <ZoomProgramsTemplate programs={programs} title={'Programas Online'} />
    </>
  );
};

export default OnlineProgramsPage;
