import React from 'react';
import BOInput from '../../atoms/BOInput';
// import BOIcon from '../../atoms/BOIcon';
import { FiSearch } from 'react-icons/fi';
// import { BiMessageRoundedDetail } from 'react-icons/bi';
// import { MdNotificationsNone } from 'react-icons/md';
// import { ICONS_SIZE } from '../../../utilities/Constant';
// import { useDispatch } from 'react-redux';
// import { searchCourses } from '../../../../app/redux/ducks/coursesSlice';
import PropTypes from 'prop-types';
// const ICONS_MOBILE_SIZE = 25;

import './index.scss';

const ContentActions = ({ keyword, onSearch }) => {
  // const [keyword, setKeyword] = useState('');
  // const dispatch = useDispatch();

  // const search_program = (ev) => {
  //   setKeyword(ev.target.value);
  //   dispatch(searchCourses({ keyword }));
  // };

  return (
    <div className="content_actions_container">
      <div className="desktop_version">
        <BOInput icon={<FiSearch />} placeholder="Buscar..." onChange={onSearch} value={keyword} />
        {/* <BOIcon>
          <BiMessageRoundedDetail size={ICONS_SIZE} />
        </BOIcon>
        <BOIcon withAlerts number={5}>
          <MdNotificationsNone size={ICONS_SIZE} />
        </BOIcon> */}
      </div>
      <div className="mobile_version">
        <BOInput icon={<FiSearch />} placeholder="Buscar..." onChange={onSearch} value={keyword} />
        {/* <BOIcon>
          <FiSearch size={ICONS_MOBILE_SIZE} color="white" />
        </BOIcon>
        <BOIcon>
          <BiMessageRoundedDetail size={ICONS_MOBILE_SIZE} color="white" />
        </BOIcon>
        <BOIcon withAlerts number={5}>
          <MdNotificationsNone size={ICONS_MOBILE_SIZE} />
        </BOIcon> */}
      </div>
    </div>
  );
};

ContentActions.propTypes = {
  onSearch: PropTypes.func,
  keyword: PropTypes.string
};
export default ContentActions;
