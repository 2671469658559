/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// import Socials from '../../molecules/Socials';
// import { MdOutlineLocationOn } from 'react-icons/md';
// import { ICONS_SIZE } from '../../../utilities/Constant';
import './index.scss';

const ProfileAboutMe = ({ data, profile_field }) => {
  return (
    <div className="profile_about_me_container bg-color-areas padding-areas-40 border-radius-30">
      <div className="areas_flex_columns_20">
        <p className="profile_titles text-color-primary">Sobre mí</p>
        <p className="profile_subtitles">Descripción</p>
        <div className="area-white">
          <textarea
            className="aboutme_text"
            rows={8}
            value={data?.about_me}
            onChange={(ev) => {
              profile_field('about_me', ev.target.value);
            }}></textarea>
        </div>
        <div style={{ marginTop: '20px' }}>
          {/*<p style={{ fontWeight: 'bold', color: '#979A9A' }}>Mis redes sociales</p>
           <Socials
            facebook={data?.url_facebook}
            instagram={data?.url_instagram}
            twitter={data?.url_twitter}
            linkedin={data?.url_linkedin}
          /> */}
        </div>
        {/* <div style={{ color: '#011754', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <p>En WeEducación desde 2018.</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdOutlineLocationOn size={ICONS_SIZE} />
            <p>Perú</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

ProfileAboutMe.propTypes = {
  data: PropTypes.object,
  profile_field: PropTypes.func
};
export default ProfileAboutMe;
