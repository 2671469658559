import React from 'react';
// import BOInput from '../../atoms/BOInput';

import './index.scss';

const ProfilePassword = () => {
  return (
    <div className="profile_account_container bg-color-areas padding-areas-40 border-radius-30">
      <div>
        <div className="areas_flex_columns_20">
          <p className="profile_titles text-color-primary">Administración de cuenta</p>
          <button
            className="btn_reset_password"
            onClick={() => window.location.replace(process.env.REACT_APP_LOGOUT_RESET_PASSWORD)}>
            Restablecer Contraseña
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePassword;
{
  /* <div className="profile_account_container bg-color-areas padding-areas-40 border-radius-30">
<div className="areas_flex_columns_20">
  <p className="profile_titles text-color-primary">Datos Personales</p>
  <div className="grid_inputs"> */
}
