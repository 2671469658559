import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'weUser',
  initialState: {
    value: { user: null }
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    loggout: (state, action) => {
      // console.log(action);
      state.value = action.payload;
    },
    updateUserData: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { login, loggout, updateUserData } = userSlice.actions;

export default userSlice.reducer;
