import React from 'react';
import './index.scss';

const BOLoading = () => {
  return (
    <div className="boloading_container">
      <div className="lds-dual-ring"></div>
      <p className="loading">Cargando...</p>
      <p></p>
    </div>
  );
};

export default BOLoading;
