import Api from './Api';
import { BASE_PATH } from './Urls';

export default class usersService {
  static async findCookiesCredentials() {
    const resp = await Api.get(`${BASE_PATH}v1/users/me/account`);
    const rspJson = await resp.json();
    return rspJson;
  }

  static async updateProfile(data) {
    const resp = await Api.putWithHeaders(`${BASE_PATH}v1/users/me/account`, data);
    const rspJson = await resp.json();
    return rspJson;
  }

  static async uploadAvatarProfile(data) {
    const resp = await Api.putWithHeaders(`${BASE_PATH}v1/users/me/account/upload_avatar`, data);
    const rspJson = await resp.json();
    return rspJson;
  }
}
