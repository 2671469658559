/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import BOVideoImage from '../../../atoms/BOVideoImage';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import BOYoutubePlayer from '../../../atoms/BOYoutubePlayer';
import './index.scss';

const CourseSesiones = ({ lectures }) => {
  const navigate = useNavigate();

  // const [url_video, seturl_video] = useState(lectures[0]?.content?.url_video);
  const [lecture, setLecture] = useState(lectures[0]);

  // useEffect(() => {
  //   console.log(lecture);
  //   console.log(lectures);
  // }, [lecture]);

  return (
    <div className="course_sesiones_container padding-areas-40">
      <div className="btn_back">
        <button onClick={() => navigate(-1)} className="border-radius-30">
          <HiOutlineArrowNarrowLeft size={22} />
          Regresar
        </button>
      </div>
      <div className="course_sesiones">
        <ul className="list_sesiones">
          {lectures &&
            lectures.map((lec, index) => (
              <li key={index} onClick={() => setLecture(lec)}>
                <button
                  className={
                    lec?.content?.url_video == lecture?.content?.url_video ? 'active' : ''
                  }>
                  {lec?.name}
                </button>
                {/* <BOVideoImage data={e} />
              <p className="title">Sección {e}: Introducción a la Logística</p> */}
              </li>
            ))}
        </ul>

        <BOYoutubePlayer
          opts={{
            height: '600',
            width: '100%',
            playerVars: {
              autoplay: 0
            }
          }}
          ytLink={lecture.content?.url_video}
        />
      </div>
    </div>
  );
};

export default CourseSesiones;
