import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const UserBasicInfo = ({ names, email, profile }) => {
  return (
    <div className="user_basic_info_container text-color-light">
      <img
        className="border-radius-50 profile_picture"
        src={profile}
        alt={names}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = require('../../../assets/perfil/blank_user.jpg');
        }}></img>
      <p className="font-bold user_name">{names}</p>
      <p className="text-weight-light user_email">{email}</p>
    </div>
  );
};

UserBasicInfo.defaultProps = {
  names: 'Usuario',
  email: 'Correo',
  profile: require('../../../assets/perfil/blank_user.jpg')
};

UserBasicInfo.propTypes = {
  names: PropTypes.string,
  email: PropTypes.string,
  profile: PropTypes.any
};

export default UserBasicInfo;
