/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ProgramTemplate from '../../../components/templates/ZoomProgramsTemplate/ProgramTemplate';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import CoursesService from '../../../Api/courses';
import BOLoading from '../../../components/atoms/BOLoading';
import { updateProgramZoom } from '../../../redux/ducks/programZoomSlice';
import { useDispatch, useSelector } from 'react-redux';

const ProgramPage = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const courseData = useSelector((state) =>
    state.programsZoomSlice.value.data?.find((el) => el?.id == id)
  );
  const [ytVideo, setYTVideo] = useState('');
  const dispatch = useDispatch();
  const fetchProgramsZoom = async (newId) => {
    const data = await CoursesService.fetchProgramsZoom(newId);
    setYTVideo(data?.data?.offline_sections[0]?.lectures[0]?.content?.url_video);
    // setCourseData(data?.data);
    // console.log(data);
    if (data) {
      dispatch(updateProgramZoom({ course: data?.data }));
      // console.log(courseData);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(courseData);
    fetchProgramsZoom(id);
  }, []);

  // useEffect(() => {
  //   if (courseData) {
  //     setLoading(false);
  //   }
  // }, [courseData]);

  return loading ? (
    <BOLoading />
  ) : (
    <>
      <ProgramTemplate ytVideo={ytVideo} courseData={courseData} />
      <div className="padding-areas-40">
        <Outlet context={courseData} />
      </div>
    </>
  );
};

export default ProgramPage;
