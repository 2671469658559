/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

import './index.scss';

const BOSubtitle = ({ title, id }) => {
  return (
    <div className="bo_subtitle_container">
      <div className="triangle" style={{ backgroundColor: '#011754' }}></div>
      <div className="bo_subtitle" style={{ backgroundColor: '#011754' }}>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default BOSubtitle;
