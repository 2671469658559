/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import navigation from '../../../utilities/navigation';
// import { AiOutlineCalendar } from 'react-icons/ai';
// import { BiTime } from 'react-icons/bi';
// import { CgFileDocument } from 'react-icons/cg';
import './index.scss';

// const ICONS_MINI = 22;
const Program = ({ data }) => {
  const navigate = useNavigate();

  const goToCourseById = (id) => {
    data.course_type.indexOf('zoom') >= 0
      ? navigate(`${navigation.programas_zoom.init}${id}`)
      : navigate(`${navigation.programas_online.init}${id}`);
  };

  return (
    <div className="program_container">
      {data && (
        <div className="program_data_container">
          <div className="program_background">
            <img
              className="border-radius-30"
              src={
                data.intranet_card_image != ''
                  ? data.intranet_card_image
                  : require(`../../organisms/Programs/images/curso1.png`)
              }></img>
          </div>
          <div className="program_data">
            <div className="data_header">
              <div className="instructor_img">
                {data?.instructors[0]?.avatar_128 !== '' ? (
                  <img src={data?.instructors[0]?.avatar_128}></img>
                ) : (
                  <img src={require(`../../organisms/Programs/images/we.png`)}></img>
                )}
              </div>
              <div className="profesor_data">
                <p className="profesor">
                  {data?.instructors[0]?.name ? `${data?.instructors[0]?.name}` : 'We Educación'}
                </p>
                {/* <p className="contacto text-weight-light">
                  {data?.instructors[0]?.name
                    ? `${data?.instructors[0]?.name.replace(/\s/g, '')}`
                    : 'We Educación'}
                </p> */}
              </div>

              {data?.course_type.indexOf('zoom') >= 0 && (
                <div className="sesiones">
                  <p className="bg-color-secondary border-radius-10">
                    {data?.total_sessions} sesiones
                  </p>
                </div>
              )}
              {data?.course_type.indexOf('online') >= 0 && (
                <div className="sesiones">
                  <p className="bg-color-secondary border-radius-10">
                    Contenido: {data?.content_time_minutes}h
                  </p>
                </div>
              )}
            </div>
            {/*
            {data?.start_date && (
              <div className="curso_bg border-radius-10">
                 <ul className="program_info">
                  {data?.start_date && (
                    <li>
                      <p>
                        <AiOutlineCalendar size={ICONS_MINI} />
                        {data?.start_date}
                      </p>
                    </li>
                  )}
                  {data?.schedule && (
                    <>
                      <div className="space"></div>
                      <li>
                        <p>
                          <BiTime size={ICONS_MINI} /> {data?.schedule}
                        </p>
                      </li>
                    </>
                  )}
                  {data?.total_sessions > 0 && data?.duration_weeks > 0 && (
                    <>
                      <div className="space"></div>
                      <li>
                        <p>
                          <CgFileDocument size={ICONS_MINI} />
                          {`${data?.total_sessions} sesiones - ${data?.duration_weeks} semanas`}
                        </p>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            )}*/}
            {/* {data?.start_date}
              {data?.schedule} */}
            <div className="bottom_data">
              <div className="curso_bg border-radius-10">
                <p className="curso">
                  <strong>{data?.name} </strong>
                  {/* {data?.description} */}
                </p>
                <p className="curso_descripcion"></p>
              </div>
              <button
                onClick={() => goToCourseById(data?.id)}
                className="continuar_curso border-radius-20">
                <p>Ingresar</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Program.propTypes = {
  data: PropTypes.object
};

export default Program;
