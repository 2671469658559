import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { IoMdDownload } from 'react-icons/io';
import { BsFillFileEarmarkPdfFill, BsFileEarmarkPptFill, BsFileEarmarkExcel } from 'react-icons/bs';
import './index.scss';
import _ from 'lodash';
import Moment from 'react-moment';
import 'moment/locale/es';

const BOTable = () => {
  // const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});
  const courseData = useOutletContext();
  const [datatable, setDataTable] = useState([]);
  // console.log(courseData.materials);

  useEffect(() => {
    setDataTable(
      _.map(courseData.materials, (el, idx) => {
        return {
          key: idx,
          type: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {el?.type == 'PDF' ? <BsFillFileEarmarkPdfFill size={30} /> : false}
              {el?.type == 'SLIDE' ? <BsFileEarmarkPptFill size={30} /> : false}
              {el?.type == 'EXCEL' ? <BsFileEarmarkExcel size={30} /> : false}
            </div>
          ),
          name: (
            <div>
              <p style={{ margin: 0 }}>
                <strong>{el?.title}</strong>
              </p>
              <p style={{ color: '#D0D0D0', fontSize: '12px', margin: 0 }}>
                <strong style={{ marginRight: '12px', color: '#00C1FF' }}>
                  {courseData?.name}
                </strong>
              </p>
            </div>
          ),
          lastUpdate: (
            <div>
              <Moment format={'DD/MM/YYYY'}>{el?.update_last}</Moment>
              <p>
                <Moment fromNow>{el?.update_last}</Moment>
              </p>
            </div>
          ),
          download: (
            <button
              onClick={() => {
                window.open(el?.download_link, '_blank', 'noopener,noreferrer');
              }}
              style={{
                border: 0,
                backgroundColor: '#FFC200',
                padding: '6px 18px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontWeight: 'bold'
              }}>
              Descargar
              <IoMdDownload size={18} />
            </button>
          ),
          sessions: (
            <>
              <ul>
                {_.map(el?.sessions, (s, idx) => (
                  <li key={idx}>
                    <p>{s}</p>
                  </li>
                ))}
              </ul>
            </>
          )
        };
      })
    );
  }, [courseData.materials]);

  // console.log(datatable);
  // const data = tableData;
  // const handleChange = (pagination, filters, sorter) => {
  //   setFilteredInfo(filters);
  //   setSortedInfo(sorter);
  // };

  const columns_zoom = [
    {
      title: <b>Sesión</b>,
      dataIndex: 'sessions',
      key: 'sessions',
      ellipsis: true,
      width: '120px'
    },
    {
      title: <b>Formato</b>,
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: '120px'
    },
    {
      title: <b>Descripción</b>,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true
    },
    // {
    //   title: 'Última actualización',
    //   dataIndex: 'lastUpdate',
    //   key: 'lastUpdate',
    //   ellipsis: true,
    //   width: '195px'
    // },
    {
      title: <b>Descargar</b>,
      dataIndex: 'download',
      key: 'download',
      ellipsis: true
    }
  ];

  const columns_online = [
    {
      title: 'Formato',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: '120px'
    },
    {
      title: 'Descripción',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true
    },
    {
      title: 'Última actualización',
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      ellipsis: true,
      width: '195px'
    },
    {
      title: 'Descargar',
      dataIndex: 'download',
      key: 'download',
      ellipsis: true
    }
  ];
  return (
    <div className="bo_table">
      {courseData.course_type.indexOf('zoom') >= 0 && (
        <Table
          columns={columns_zoom}
          dataSource={datatable}
          pagination={false}
          // scroll={{ x: 1000 }}
        />
      )}
      {courseData.course_type.indexOf('online') >= 0 && (
        <Table
          columns={columns_online}
          dataSource={datatable}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      )}
    </div>
  );
};

export default BOTable;
