/* eslint-disable react/prop-types */
import React from 'react';
import './index.scss';

const BOButton = ({ className, icon, children, ...props }) => {
  return (
    <button className={`bo_button border-radius-30 ${className}`} {...props}>
      {icon && (
        <div className="div_icon" style={{ marginRight: '8px' }}>
          {icon}
        </div>
      )}
      {children}
    </button>
  );
};

export default BOButton;
