/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ProfileAboutMe from '../../organisms/ProfileAboutMe';
import ProfileAccount from '../../organisms/ProfileAccount';
import ProfilePassword from '../../organisms/ProfilePassword';
// import ContentActions from '../../organisms/ContentActions';
import { updateUserData } from '../../../../app/redux/ducks/userSlice';
import { useDispatch } from 'react-redux';
import UsersService from '../../../Api/users';
// import { Button, Upload } from 'antd';
// import { BsFillCloudUploadFill } from 'react-icons/bs';
import './index.scss';
import _ from 'lodash';
import { toBase64, toImage } from './../../../shared/functions/functions';
import { FaSpinner } from 'react-icons/fa';

const UserProfileTemplate = ({ data }) => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(data);
  const [showsave, setShowsave] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  // const [avatar, setAvatar] = useState(data?.avatar_128);

  const save = async () => {
    setLoading(true);
    var new_profile = await UsersService.updateProfile(profile);
    dispatch(updateUserData(new_profile.data));
    setLoading(false);
  };

  const profile_field = (field, value) => {
    var profile_tmp = _.clone(profile);
    if (field == 'names' || field == 'surnames') {
      profile_tmp[field] = value.toUpperCase();
    } else {
      profile_tmp[field] = value;
    }
    setProfile(profile_tmp);
  };

  const uploadAvatar = async (ev) => {
    var file = ev.target.files[0];
    var file_b64 = await toBase64(file);
    var image = await toImage(file);
    var rate = image.width / image.height;
    if (rate > 1.3 || rate < 0.7) {
      alert('El ancho y alto de la imagen debe ser cercano a 1.0');
      return;
    } else {
      // setAvatar(file_b64);
      var data = {
        avatar: file_b64.split(',')[1]
      };
      setLoading(true);
      var profile = await UsersService.uploadAvatarProfile(data);
      // console.log(profile.data);
      _.assignIn(profile.data, { avatar_128: file_b64, avatar_256: file_b64 });
      dispatch(updateUserData(profile.data));
      setLoading(false);
    }
  };

  useEffect(() => {
    var err = {};
    var cmp1 = _.clone(data);
    var cmp2 = _.clone(profile);
    _.unset(cmp1, 'avatar_128');
    _.unset(cmp1, 'avatar_256');
    _.unset(cmp2, 'avatar_128');
    _.unset(cmp2, 'avatar_256');

    if (profile.identification_number.length != 8 && profile.identification_type == 'DNI') {
      err['identification_number'] = 'Si el campo es DNI el valor debe ser un valor de 8 números';
      setErrors(err);
    } else {
      setErrors({});
    }
    setShowsave(_.isEqual(cmp1, cmp2) == false && _.isEmpty(errors));
  }, [profile, data]);

  return (
    <div className="user_profile_template_container content-areas">
      {data && (
        <div className="user_profile_flex">
          <div className="aboutme_container">
            <div className="aboutme_photo">
              <img
                style={{ width: '150px' }}
                className="border-radius-50"
                src={data?.avatar_256}
                alt={data?.name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require('../../../assets/perfil/blank_user.jpg');
                }}
              />
              {loading ? (
                <FaSpinner size={20} />
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  onChange={uploadAvatar}
                  aria-label="Archivo"></input>
              )}
            </div>
            <ProfileAboutMe data={profile} profile_field={profile_field} />
          </div>

          <div className="account_container">
            <ProfileAccount data={profile} errors={errors} profile_field={profile_field} />
            {showsave ? (
              <div className="save_changes">
                <button
                  className="border-radius-30 active"
                  onClick={save}
                  style={{ cursor: 'pointer' }}>
                  {loading ? (
                    <div className="d-flex">
                      <span>Guardando ... </span>
                      <FaSpinner size={20}></FaSpinner>
                    </div>
                  ) : (
                    <>Guardar Cambios </>
                  )}
                </button>
              </div>
            ) : (
              <div className="save_changes">
                <button className="border-radius-30">Guardar Cambios</button>
              </div>
            )}
            <ProfilePassword />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileTemplate;
