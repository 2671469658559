import React from 'react';
import { useOutletContext } from 'react-router-dom';
//import BOVideoImage from '../../atoms/BOVideoImage';
import { BiSlideshow } from 'react-icons/bi';
import './index.scss';

const CourseGrabadas = () => {
  //const navigate = useNavigate();

  const courseData = useOutletContext();

  // console.log('couserData en coursGrabadas', courseData);

  return (
    <div className="course_grabadas_container">
      <a
        href={courseData.last_slide}
        target="_blank"
        rel="noreferrer"
        style={{ marginTop: '20px', padding: '7px 25px', displayFlex: 1, alignItems: 'center' }}
        className="text-color-dark border-radius-20 bg-color-secondary">
        <BiSlideshow size={20} />
        <span style={{ paddingLeft: 8 }}>Ir a Clases</span>
      </a>
    </div>
  );
  /**
  return (
    <div className="course_grabadas_container">
      <ul>
        {courseData?.offline_sections?.map((e, index) => (
          <li
            key={index}
            onClick={() =>
              navigate(`sesion/${index + 1}`, { replace: false, state: { lectures: e?.lectures } })
            }>
            <BOVideoImage data={e} />
            <p className="title">{e?.name}</p>
            <p>{`Duración: ${e?.total_duration_minutes}h / Lecciones: ${e?.lectures.length}`}</p>
          </li>
        ))}
      </ul>
    </div>
  ); */
};

export default CourseGrabadas;
