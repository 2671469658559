const mainRoutes = {
  profile: '/perfil',
  programas_zoom: '/programas_zoom',
  programas_online: '/programas_online',
  certificates: '/certificados'
};

export default {
  profile: {
    init: `${mainRoutes.profile}/`
  },
  programas_zoom: {
    init: `${mainRoutes.programas_zoom}/`,
    program: `${mainRoutes.programas_zoom}/`,
    programId: `${mainRoutes.programas_zoom}/:id`,
    about: `${mainRoutes.programas_zoom}/:id/about`,
    recorded: `${mainRoutes.programas_zoom}/:id/recorded`,
    liveCourse: `${mainRoutes.programas_zoom}/:id/live`,
    materials: `${mainRoutes.programas_zoom}/:id/materials`,
    finalProject: `${mainRoutes.programas_zoom}/:id/finalproject`
  },
  programas_online: {
    init: `${mainRoutes.programas_online}/`,
    online: `${mainRoutes.programas_online}/`,
    onlineId: `${mainRoutes.programas_online}/:id`,
    about: `${mainRoutes.programas_online}/:id/about`,
    recorded: `${mainRoutes.programas_online}/:id/recorded`,
    liveCourse: `${mainRoutes.programas_online}/:id/live`,
    materials: `${mainRoutes.programas_online}/:id/materials`,
    finalProject: `${mainRoutes.programas_online}/:id/finalproject`
  },
  certificates: {
    init: `${mainRoutes.certificates}/`
  },
  mainRoutes
};
