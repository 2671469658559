/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BOButton from '../../atoms/BOButton';
import { BsFillCameraVideoFill, BsCreditCard, BsFillCaretRightSquareFill } from 'react-icons/bs';
import { TbCertificate } from 'react-icons/tb';
// import { BiMessageRoundedDetail } from 'react-icons/bi';
// import { FaGraduationCap } from 'react-icons/fa';
import navigation from '../../../utilities/navigation';

import './index.scss';

const ICONS_SIZE = 30;

const NavBar = ({ hiddeMenu }) => {
  const [courseOnline, setCourseOnline] = useState('');
  const [courseOffline, setCourseOffline] = useState('');
  const [courseCertificate, setCourseCertificate] = useState('');

  var location = useLocation().pathname;

  useEffect(() => {
    if (location.includes('programas_zoom')) {
      setCourseOnline('programasActive');
      setCourseOffline('');
      setCourseCertificate('');
    } else if (location.includes('programas_online')) {
      setCourseOffline('offlineActive');
      setCourseOnline('');
      setCourseCertificate('');
    } else if (location.includes('certificados')) {
      setCourseCertificate('certificateActive');
      setCourseOffline('');
      setCourseOnline('');
    } else {
      setCourseCertificate('');
      setCourseOffline('');
      setCourseOnline('');
    }
  }, [location]);

  return (
    <nav className="navbar_container">
      <ul>
        <li>
          <Link to={navigation.programas_zoom.init} onClick={() => hiddeMenu()}>
            <BOButton
              className={`text-color-light bo_button_nav_item ${courseOnline}`}
              icon={<BsFillCameraVideoFill size={ICONS_SIZE} />}>
              Programas Zoom
            </BOButton>
          </Link>
        </li>
        <li>
          <Link to={navigation.programas_online.init} onClick={() => hiddeMenu()}>
            <BOButton
              className={`text-color-light bo_button_nav_item ${courseOffline}`}
              icon={<BsFillCaretRightSquareFill size={ICONS_SIZE} />}>
              Cursos Online
            </BOButton>
          </Link>
        </li>
        <li>
          <div onClick={() => window.open(`${process.env.REACT_APP_WE_BASE}/my/cuotas`)}>
            <BOButton
              className={`text-color-light bo_button_nav_item`}
              icon={<BsCreditCard size={ICONS_SIZE} />}>
              Mis pagos y Cuotas
            </BOButton>
          </div>
        </li>
        <li>
          <Link to={navigation.certificates.init} onClick={() => hiddeMenu()}>
            <BOButton
              className={`text-color-light bo_button_nav_item ${courseCertificate}`}
              icon={<TbCertificate size={ICONS_SIZE} />}>
              Mis Certificados
            </BOButton>
          </Link>
        </li>
        {/* <li>
          <Link to="/" onClick={() => hiddeMenu()}>
            <BOButton
              className="text-color-light bo_button_nav_item"
              icon={<BiMessageRoundedDetail size={ICONS_SIZE} />}>
              Notificaciones
            </BOButton>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default NavBar;
