import React from 'react';
import HomeTemplate from '../../components/templates/HomeTemplate';
import { userData } from '../../core/state/UserProfile/selector';

const HomePage = () => {
  // window.open("/#/programas_zoom")
  return <HomeTemplate data={userData} />;
};

export default HomePage;
