export default class Api {
  static get(URL) {
    return fetch(URL, {
      method: 'get',
      credentials: 'include'
    });
  }

  static postWithHeaders(URL, data) {
    var raw = JSON.stringify(data);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    return fetch(URL, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      credentials: 'include',
      redirect: 'follow'
    });
  }
  static putWithHeaders(URL, data) {
    var raw = JSON.stringify(data);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    return fetch(URL, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      credentials: 'include'
      // redirect: 'follow'
    });
  }
  static deleteWithHeaders(URL, data) {
    var raw = JSON.stringify(data);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    return fetch(URL, {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      credentials: 'include',
      redirect: 'follow'
    });
  }
}
