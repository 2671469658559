import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const coursesSlice = createSlice({
  name: 'weCourses',
  initialState: {
    value: { weCourses: [], data: [] }
  },
  reducers: {
    setCourses: (state, action) => {
      state.value['data'] = action.payload.data.map((el) => ({ ...el, show: true }));
      // console.log(state.value);
    },
    getCourses: (state, action) => {
      state.value['data'] = action.payload.data;
    },
    searchCourses: (state, action) => {
      var data = _.clone(state.value.data);
      var show;
      data = data.map((el) => {
        show = true;
        if (
          el.name.toLowerCase().indexOf(action.payload.keyword.toLowerCase()) == -1 &&
          el.name.length >= 3
        ) {
          show = false;
        }
        return { ...el, show };
      });
      // console.log(data);
      state.value['data'] = data;
    }
  }
});

export const { setCourses, getCourses, searchCourses } = coursesSlice.actions;

export default coursesSlice.reducer;
