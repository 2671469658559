import React from 'react';
import PropTypes from 'prop-types';
import BOSubtitle from '../../atoms/BOSubtitle';
import Program from '../../molecules/Program';
import _ from 'lodash';
// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
// import { EffectCoverflow, Pagination } from 'swiper';

import './index.scss';

const Programs = ({ data, title }) => {
  if (title == 'Programas Zoom') {
    // var total_items = data?.length || 0;
    // var title_program = data[0]?.parent_course?.name;
    // var i = 0;
    // var els = [];
    var content = [];

    // var parent_courses = ;
    _.forEach(_.uniqBy(data, 'parent_course.name'), (parent) => {
      var els = [];
      _.forEach(data, (course, index) => {
        if (parent?.parent_course?.name == course?.parent_course?.name) {
          els.push(<Program key={index} data={course} />);
        }
      });
      content.push(
        <div className="program_container_flex" key={parent?.parent_course?.id}>
          <BOSubtitle id={parent?.parent_course?.id} title={parent?.parent_course?.name} />
          <div className="program_desktop">
            <div className="programs_grid">{els}</div>
          </div>
        </div>
      );
    });

    // console.log(parent_course_name);

    // while (i < total_items) {
    //   console.log(title_program);
    //   if (i == total_items) {
    //     content.push(
    //       <div className="program_container_flex">
    //         <BOSubtitle id={title_program} title={title_program} key={i} />
    //         <div className="program_desktop">
    //           <div className="programs_grid">{els}</div>
    //         </div>
    //       </div>
    //     );
    //   } else if (title_program != data[i]?.parent_course?.name) {
    //     content.push(
    //       <div className="program_container_flex">
    //         <BOSubtitle id={title_program} title={title_program} key={i} />
    //         <div className="program_desktop">
    //           <div className="programs_grid">{els}</div>
    //         </div>
    //       </div>
    //     );
    //     title_program = data[i]?.parent_course?.name;
    //   } else {
    //     els = [];
    //     while (title_program == data[i]?.parent_course?.name) {
    //       console.log(data[i].name);
    //       els.push(<Program key={i} data={data[i]} />);
    //       i++;
    //     }
    //   }
    // }

    return (
      <div className="programs_container">
        {data?.length > 0 && content}
        {data?.length == 0 && (
          <>
            <div className="program_container_flex">
              <div className="program_without_results ">
                <span>Aún no tienes cursos disponibles.</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="programs_container">
        {data?.length > 0 && (
          <div className="program_container_flex">
            <BOSubtitle
              id={data?.id}
              title={`Total: ${data?.filter((el) => el?.show).length} ${title}`}
            />
            <div className="program_desktop">
              <div className="programs_grid">
                {data
                  ?.filter((r) => r?.show)
                  .map((e, index) => (
                    <Program key={index} data={e} />
                  ))}
              </div>
            </div>
          </div>
        )}
        {data?.length == 0 && (
          <>
            <div className="program_container_flex">
              <div className="program_without_results ">
                <span>Aún no tienes cursos disponibles.</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

Programs.propTypes = {
  data: PropTypes.array,
  title: PropTypes.any
};

export default Programs;
