import React from 'react';
import LiveSessions from '../../molecules/LiveSessions';
import { useOutletContext } from 'react-router-dom';
import './index.scss';

const CourseLive = () => {
  const course = useOutletContext();

  return (
    <div className="course_live_container">
      <ul>
        {course &&
          course?.sessions
            .filter((e) => e.start_datetime && e.end_datetime)
            .map((e, index) => (
              <li key={index}>
                <LiveSessions session={e} />
              </li>
            ))}
      </ul>
      {course &&
        course?.sessions?.filter((e) => e.start_datetime && e.end_datetime).length == 0 && (
          <p>No hay sesiones programadas</p>
        )}
    </div>
  );
};

export default CourseLive;
