/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ProgramTemplate from '../../../components/templates/ZoomProgramsTemplate/ProgramTemplate';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import CoursesService from '../../../Api/courses';
import BOLoading from '../../../components/atoms/BOLoading';

const OnlineProgramPage = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState();
  const [ytVideo, setYTVideo] = useState('');

  const fetchProgramsOnline = async (newId) => {
    const data = await CoursesService.fetchProgramsOnline(newId);
    setYTVideo(data?.data?.offline_sections[0]?.lectures[0]?.content?.url_video);
    setCourseData(data?.data);
  };

  useEffect(() => {
    fetchProgramsOnline(id);
  }, []);

  useEffect(() => {
    if (courseData) {
      setLoading(false);
    }
  }, [courseData]);

  return loading ? (
    <BOLoading />
  ) : (
    <>
      <ProgramTemplate ytVideo={ytVideo} courseData={courseData} />
      <div className="padding-areas-40">
        <Outlet context={courseData} />
      </div>
    </>
  );
};

export default OnlineProgramPage;
