import React from 'react';
import TitleAndText from '../../molecules/TitleAndText';
import BOProject from '../../atoms/BOProject';
import { useOutletContext } from 'react-router-dom';
import './index.scss';

const parrafo = [
  'SAP es unos de los ERPS más utilizados por las empresas líderes en el mundo. En nuestro curso de SAP Módulo Logístico, desde CERO, te enseñaremos las transacciones más importantes en la gestión de compras e inventarios. En el Perú empresas como ALICORP, NESTLE, GLORIA, DINET y muchas más consideran SAP MM como requisito indispensable para trabajar con ellos.'
];

const CourseFinalProject = () => {
  // const data = [1, 2, 3];

  const courseData = useOutletContext();

  return (
    <div className="course_final_container">
      <TitleAndText title={'Proyectos'} arrayText={parrafo} />
      {courseData?.projects?.length > 0 && (
        <ul>
          {courseData.projects.map((project, index) => (
            <li key={index}>
              <BOProject project={project} course={courseData} key={index} />
            </li>
          ))}
        </ul>
      )}
      {courseData?.projects?.length == 0 && (
        <>
          <span>No tienes proyectos o trabajos asignados aún.</span>
        </>
      )}
    </div>
  );
};

export default CourseFinalProject;
