/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ContentActions from '../../organisms/ContentActions';
import Programs from '../../organisms/Programs';
//import { programs } from '../../../core/state/ZoomPrograms/selector';
import { useDispatch } from 'react-redux';
import { searchProgramsZoom } from '../../../../app/redux/ducks/programZoomSlice';
import { searchProgramsOnline } from '../../../../app/redux/ducks/programOnlineSlice';
import './index.scss';

const ZoomProgramsTemplate = ({ programs, title }) => {
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const onSearch = (ev) => {
    setKeyword(ev.target.value);
  };

  useEffect(() => {
    dispatch(searchProgramsZoom({ keyword }));
    dispatch(searchProgramsOnline({ keyword }));
  }, [keyword]);

  return (
    <div className="zoom_programas content-areas">
      {/* <div className="desktop_version"> */}
      <ContentActions keyword={keyword} onSearch={onSearch} />
      {/* </div> */}
      <div>{programs && <Programs data={programs} title={title} />} </div>
    </div>
  );
};

export default ZoomProgramsTemplate;
