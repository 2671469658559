/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import CoursesService from '../../../../Api/courses';
import { useNavigate, useParams } from 'react-router';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import BOYoutubePlayer from '../../../atoms/BOYoutubePlayer';
import {
  BsStarFill,
  BsCameraVideo,
  BsPlayCircle,
  BsPeopleFill,
  BsArrowUpRightCircleFill
} from 'react-icons/bs';
import { AiOutlineCalendar, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiTime, BiDesktop } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import { RiNewspaperLine } from 'react-icons/ri';
import { MdWorkOutline } from 'react-icons/md';
import './index.scss';
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import _ from 'lodash';
const ICONS_MINI = 22;

const WorkTeam = ({ title, members }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <p onClick={() => setOpen(true)}>
        <BsPeopleFill size={ICONS_MINI} />
        <b>{title}</b> <BsArrowUpRightCircleFill />
      </p>
      <Modal
        hideBackdrop
        open={open}
        style={{
          top: '60px'
        }}
        onCancel={() => {
          setOpen(false);
        }}
        footer={[]}>
        <div>
          <div style={{ textAlign: 'center' }}>
            <b style={{ fontSize: '18px' }}>{title}</b>
          </div>
          <p />
          <p>{`Cantidad de Miembros: ${members.length}`}</p>
          <ol>
            {_.map(members, (member) => (
              <li key={member.id}>
                <b> {member.name}</b>
                <ul>
                  {member.email && <li>{member.email}</li>}
                  {member.mobile && <li>{member.mobile}</li>}
                </ul>
              </li>
            ))}
          </ol>
        </div>
      </Modal>
    </>
  );
};

const ProgramTemplate = ({ courseData, ytVideo }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  // if (courseData?.course_type?.indexOf('online') == 0) {
  //   navigate('recorded', { state: { courseData: courseData } });
  // }
  const [firstagain, setfirstagain] = useState(true);
  const [aboutPath, setAboutPath] = useState('');
  const [livePath, setLivePath] = useState('');
  const [recordedPath, setRecordedPath] = useState('');
  const [materialsPath, setMaterialsPath] = useState('');
  const [finalProjectPath, setFinalProjectPath] = useState('');

  useEffect(() => {
    if (location.includes('recorded')) {
      setRecordedPath('recordedActive');
      setLivePath('');
      setMaterialsPath('');
      setFinalProjectPath('');
      setAboutPath('');
    } else if (location.includes('live')) {
      setRecordedPath('');
      setLivePath('liveActive');
      setMaterialsPath('');
      setFinalProjectPath('');
      setAboutPath('');
      // console.log('Liove', livePath);
    } else if (location.includes('materials')) {
      setRecordedPath('');
      setLivePath('');
      setMaterialsPath('materialsActive');
      setFinalProjectPath('');
      setAboutPath('');
    } else if (location.includes('finalproject')) {
      setRecordedPath('');
      setLivePath('');
      setMaterialsPath('');
      setFinalProjectPath('finalProjectActive');
      setAboutPath('');
    } else {
      setRecordedPath('');
      setLivePath('');
      setMaterialsPath('');
      setFinalProjectPath('');
      setAboutPath('aboutActive');
      /*
      if (courseData?.course_type?.indexOf('online') == 0 && firstagain) {
        navigate('recorded', { state: { courseData: courseData } });
        setfirstagain(false);
      }*/
    }
  }, [location]);
  // console.log(ytVideo);
  return (
    <div className="program_template_container">
      <div className="program_header">
        <div className="program_background">
          <img src={require('../../../../assets/courses/cursoportada.png')}></img>
        </div>
        <div className="linear_gradient"></div>
        <div className="program_header_content padding-areas-40">
          {courseData?.course_type && (
            <div className="program_content">
              <div className="btn_back_to_zoom">
                {courseData?.course_type?.indexOf('zoom') >= 0 && (
                  <button onClick={() => navigate('/programas_zoom/')} className="border-radius-30">
                    <HiOutlineArrowNarrowLeft size={22} />
                    Regresar a programas Zoom
                  </button>
                )}

                {courseData?.course_type.indexOf('online') >= 0 && (
                  <button
                    onClick={() => navigate('/programas_online/')}
                    className="border-radius-30">
                    <HiOutlineArrowNarrowLeft size={22} />
                    Regresar a programas Online
                  </button>
                )}
              </div>
              <div className="video_container_up">
                {ytVideo && (
                  <div className="video_container">
                    <BOYoutubePlayer
                      ytLink={ytVideo}
                      opts={{ height: 160, width: 320, playerVars: { fs: 0 } }}
                    />
                  </div>
                )}
                {ytVideo == undefined ? (
                  <div className="program_header_content_image">
                    <img src={courseData?.image_512} className="program_header_image" />
                  </div>
                ) : (
                  false
                )}
              </div>
              {/* <div className="video_container_mobile">
                <img src={require('../../../../assets/courses/cursovideo.png')} />
              </div> */}
              <div className="curso_info_header">
                <p className="curso_titulo">{courseData?.name}</p>
                <p className="curso_descripcion">{courseData?.description}</p>
                {/*                 <p className="calificacion">
                  <p className="value">{courseData?.rating_avg / 2}</p>
                  <div className="stars">
                    <BsStarFill color={'#FFC22B'} />
                    <BsStarFill color={'#FFC22B'} />
                    <BsStarFill color={'#FFC22B'} />
                    <BsStarFill color={'#FFC22B'} />
                    <BsStarFill color={'#CBCED0'} />
                  </div>
                  <p>({courseData?.reviews_count} Calificaciones)</p>
                </p> */}
              </div>
              <div className={`curso_info_bottom ${courseData?.course_type}`}>
                {courseData?.course_type.indexOf('zoom') == 0 ? (
                  <div>
                    <p className="curso_info_bottom_title">Información del curso</p>
                    <ul className="curso_info_lista">
                      <div className="space"></div>
                      {courseData?.start_date && (
                        <>
                          <li>
                            <p>
                              <AiOutlineCalendar size={ICONS_MINI} />
                              {`Inicio: ${courseData?.start_date}`}
                            </p>
                          </li>
                          <div className="space"></div>
                        </>
                      )}
                      {courseData?.schedule && (
                        <>
                          <li>
                            <p>
                              <BiTime size={ICONS_MINI} /> {courseData?.schedule}
                            </p>
                          </li>
                          <div className="space"></div>
                        </>
                      )}
                      {courseData?.total_sessions > 0 && courseData?.duration_weeks > 0 && (
                        <>
                          <li>
                            <p>
                              <CgFileDocument size={ICONS_MINI} />
                              {`${courseData?.total_sessions} sesiones`}
                            </p>
                          </li>
                          <div className="space"></div>
                        </>
                      )}
                      {courseData?.work_team && (
                        <>
                          <li>
                            <WorkTeam
                              title={`Equipo de trabajo: #${courseData?.work_team?.split('-')[1]}`}
                              members={courseData?.work_team_members}
                            />
                          </li>
                          <div className="space"></div>
                        </>
                      )}
                      {/* <>
                        <li>
                          <p>
                            <BsCameraVideo size={ICONS_MINI} />
                            Transmisión através de Zoom
                          </p>
                        </li>
                        <div className="space"></div>
                      </> */}
                      {/* <div className="space"></div>
                      <li>
                        <p>
                          <BsCameraVideo size={ICONS_MINI} />
                          Programa grabado vía Intranet
                        </p>
                      </li> */}
                    </ul>
                  </div>
                ) : (
                  false
                )}
                <div className="about_teacher">
                  <p className="curso_info_bottom_title">Acerca del profesor</p>
                  <div className={`about_bottom ${courseData?.course_type}`}>
                    {courseData?.instructors[0]?.avatar_128 ? (
                      <img
                        className="proffessor_photo"
                        src={courseData?.instructors[0]?.avatar_128}
                        alt={courseData?.instructors[0]?.name}></img>
                    ) : (
                      <img
                        className="proffessor_photo"
                        src={require(`../../../organisms/Programs/images/we.png`)}
                        alt={courseData?.instructors[0]?.name}></img>
                    )}
                    <div>
                      <p className="proffessor_name">{courseData?.instructors[0]?.name}</p>
                      <p className="proffesor_info">{courseData?.instructors[0]?.function}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="video_container_down">
            {ytVideo && (
              <div className="video_container">
                <BOYoutubePlayer ytLink={ytVideo} opts={{ height: 300, width: 490 }} />
              </div>
            )}
            {ytVideo == undefined ? (
              <div className="program_header_content_image">
                <img src={courseData?.image_512} className="program_header_image" />
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
      <div></div>
      <div className="program_content_container">
        <div className="ul_routes">
          <ul>
            {courseData?.course_type?.indexOf('zoom') == 0 && (
              <li>
                <button onClick={() => navigate(``, { replace: false })}>
                  <AiOutlineInfoCircle size={ICONS_MINI} />
                  <div className={`arrow ${aboutPath}`}></div>
                  Información
                </button>
              </li>
            )}
            {courseData?.sessions?.length > 0 ? (
              <li>
                <button onClick={() => navigate(`live`, { replace: false })}>
                  <div className={`arrow ${livePath}`}></div>
                  <BiDesktop size={ICONS_MINI} />
                  Clases
                </button>
              </li>
            ) : (
              false
            )}

            {courseData?.course_type?.indexOf('online') == 0 && (
              <li>
                <button onClick={() => navigate(``, { replace: false })}>
                  <AiOutlineInfoCircle size={ICONS_MINI} />
                  <div className={`arrow ${aboutPath}`}></div>
                  Información
                </button>
              </li>
            )}
            {courseData?.course_type?.indexOf('online') == 0 ? (
              <li>
                <button
                  onClick={() => {
                    window.location.href = courseData.last_slide;
                  }}>
                  <div className={`arrow ${recordedPath}`}></div>
                  <BsPlayCircle size={ICONS_MINI} />
                  VideoClases
                </button>
              </li>
            ) : (
              false
            )}
            {/* {courseData?.materials?.length > 0 && (
              <li>
                <button onClick={() => navigate('materials', { replace: false })}>
                  <div className={`arrow ${materialsPath}`}></div>
                  <RiNewspaperLine size={ICONS_MINI} />
                  Materiales
                </button>
              </li>
            )} */}
            {courseData?.course_type?.indexOf('zoom') == 0 && (
              <li>
                <button onClick={() => navigate('finalproject', { replace: false })}>
                  <div className={`arrow ${finalProjectPath}`}></div>
                  <MdWorkOutline size={ICONS_MINI} />
                  Proyectos
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProgramTemplate;
