import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState: {
    value: { data: [] }
  },
  reducers: {
    setCertificates: (state, action) => {
      state.value['data'] = action.payload.data;
    },
    getCertificates: (state, action) => {
      state.value['data'] = action.payload.data;
    },
    searchCertificates: (state, action) => {
      state.value['data'] = action.payload.data;
    }
  }
});

export const { setCertificates, getCertificates, searchCertificates } = certificatesSlice.actions;

export default certificatesSlice.reducer;
