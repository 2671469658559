/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Routes,
  Route,
  Link,
  Navigate,
  BrowserRouter
} from 'react-router-dom';
import HomePage from './app/pages/Home';
import ZoomProgramsPage from './app/pages/ZoomPrograms';
import OnlineProgramsPage from './app/pages/OnlinePrograms';
import UserProfilePage from './app/pages/UserProfile';
import ProgramPage from './app/pages/ZoomPrograms/Program';
import OnlineProgramPage from './app/pages/OnlinePrograms/Program';
import CourseAbout from './app/components/organisms/CourseAbout';
import CourseLive from './app/components/organisms/CourseLive';
import CourseFinalProject from './app/components/organisms/CourseFinalProject';
import CourseMaterials from './app/components/organisms/CourseMateriales';
import CourseGrabadas from './app/components/organisms/CourseGrabadas';
import navigation from './app/utilities/navigation';
import CoursesService from './app/Api/courses';
import UsersService from './app/Api/users';
import SesionsPage from './app/pages/ZoomPrograms/Program/Sesions';
import CertificatesPage from './app/pages/Certificates';

import { useDispatch, useSelector } from 'react-redux';
import { setProgramsOnline } from './app/redux/ducks/programOnlineSlice';
import { setProgramsZoom } from './app/redux/ducks/programZoomSlice';
import { login, loggout } from './app/redux/ducks/userSlice';
import { setCertificates } from './app/redux/ducks/certificatesSlice';
import './App.scss';

const App = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userSlice.value);

  const fetchListProgramsOnline = async () => {
    try {
      const rsp = await CoursesService.fetchListProgramsOnline();
      dispatch(setProgramsOnline(rsp));
    } catch (err) {
      console.log('err', err);
    }
  };

  const fetchListProgramsZoom = async () => {
    try {
      const rsp = await CoursesService.fetchListProgramsZoom();
      dispatch(setProgramsZoom(rsp));
    } catch (err) {
      console.log('err', err);
    }
  };
  const fetchListCertificates = async () => {
    try {
      const rsp = await CoursesService.fetchListCertificates();
      dispatch(setCertificates(rsp));
    } catch (err) {
      console.log('err', err);
    }
  };

  const getUserInfo = async () => {
    try {
      const rsp = await UsersService.findCookiesCredentials();
      if (rsp.data) {
        dispatch(login(rsp.data));
      } else {
        dispatch(loggout({ user: null }));
      }
    } catch (err) {
      dispatch(loggout({ user: null }));
      window.location.replace(process.env.REACT_APP_LOGOUT_REDIRECTS_TO);
    }
  };

  useEffect(() => {
    fetchListProgramsOnline();
    fetchListProgramsZoom();
    fetchListCertificates();
    getUserInfo();
  }, []);

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="" element={<HomePage />}>
            <Route path="/" element={<ZoomProgramsPage />}></Route>
            <Route path={navigation.programas_zoom.init} element={<ZoomProgramsPage />}></Route>
            <Route path={navigation.profile.init} element={<UserProfilePage />} />
            <Route path={navigation.programas_zoom.programId} element={<ProgramPage />}>
              <Route index element={<CourseAbout />}></Route>
              <Route path="recorded" element={<CourseGrabadas />}></Route>
              <Route path="live" element={<CourseLive />}></Route>
              <Route path="materials" element={<CourseMaterials />}></Route>
              <Route path="finalproject" element={<CourseFinalProject />}></Route>
            </Route>
            <Route
              path={`${navigation.programas_zoom.recorded}/sesion/:nsesion`}
              element={<SesionsPage />}></Route>
            <Route path={navigation.programas_online.init} element={<OnlineProgramsPage />}></Route>
            <Route path={navigation.programas_online.onlineId} element={<OnlineProgramPage />}>
              <Route index element={<CourseAbout />}></Route>
              <Route path="recorded" element={<CourseGrabadas />}></Route>
              <Route path="materials" element={<CourseMaterials />}></Route>
              <Route path="finalproject" element={<CourseFinalProject />}></Route>
            </Route>
            <Route
              path={`${navigation.programas_online.recorded}/sesion/:nsesion`}
              element={<SesionsPage />}></Route>
            <Route path={`${navigation.certificates.init}`} element={<CertificatesPage />} />
            <Route path="*" exact={true} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
