/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { youtubeParser } from '../../../shared/functions/functions';

const BOYoutubePlayer = ({ ytLink, opts }) => {
  const [urlVideo, setUrlVideo] = useState('');

  // const onready = (e) => {
  //   e.target.pauseVideo();
  // };

  useEffect(() => {
    const ytId = youtubeParser(ytLink || urlVideo);
    setUrlVideo(ytId);
  }, [ytLink]);

  return <YouTube videoId={urlVideo} opts={opts} />;
};

BOYoutubePlayer.defaultProps = {
  opts: {
    height: '292',
    width: '480',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 0
    }
  },
  ytLink: ''
};

BOYoutubePlayer.propTypes = {
  opts: PropTypes.object,
  ytLink: PropTypes.string
};

export default BOYoutubePlayer;
