import React from 'react';
import { Collapse } from 'antd';
import { TiDocumentText } from 'react-icons/ti';
// import { VscFilePdf } from 'react-icons/vsc';
import { BiCheck } from 'react-icons/bi';
import {
  BsClock,
  BsFillFileEarmarkPdfFill,
  BsFileEarmarkPptFill,
  BsFileEarmarkExcel,
  BsFileEarmarkWordFill
} from 'react-icons/bs';
// import { FiImage } from 'react-icons/fi';
// import { message, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
const { Panel } = Collapse;
import _ from 'lodash';
import './index.scss';
import Moment from 'react-moment';
import BOPresentation from '../BOPresentation';
import CoursesService from './../../../Api/courses';
import { updatePresentations } from '../../../redux/ducks/programZoomSlice';
// const ICONS_COLLAPSE_SIZE = 25;
// const ICONS_COLLAPSE_COLOR = '#ACACAC';

// const { Dragger } = Upload;
// const props = {
//   name: 'file',
//   multiple: true,
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',

//   onChange(info) {
//     const { status } = info.file;

//     if (status !== 'uploading') {
//       console.log('no uploading');
//     }

//     if (status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === 'error') {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },

//   onDrop(e) {
//     console.log('err', e);
//   }
// };

const BOProject = ({ project, course }) => {
  // const onChange = (key) => {
  //   console.log(project);
  // };
  // console.log(project);
  const Content = (
    <div className="content_panel_container">
      <div className="desktop_version">
        <div className="left">
          <div className="icon">
            <TiDocumentText size={35} color="white" />
          </div>
          <div className="info">
            <p className="title">{project.title}</p>
            <p className="publicado">
              Fecha límite: <Moment format={'DD-MM-YYYY'}>{project.deadline}</Moment>
            </p>
            <p>
              <Moment fromNow>{project.deadline}</Moment>
            </p>
          </div>
        </div>
        {project?.my_presentations?.map((el, index) => (
          <div key={index}>
            {el.attachments.length > 0 && el.message != '' && (
              <div className="right">
                <button className="btn done">
                  <BiCheck size={22} />
                  Entregado
                </button>
              </div>
            )}
            {el.attachments.length == 0 && el.message == '' && (
              <div className="right">
                <button className="btn pending">Borrador</button>
              </div>
            )}
          </div>
        ))}
        {/* {project?.my_presentations.length > 0 && (
          <div className="right">
            <button className="btn done">
              <BiCheck size={22} />
              Entregado
            </button>
          </div>
        )} */}
        {project?.my_presentations.length == 0 && (
          <div className="right">
            <button className="btn pending">
              <BsClock size={22} />
              Pendiente
            </button>
          </div>
        )}
      </div>
      {/* <div className="mobile_version">
        <div className="left">
          <div className="icon">
            <TiDocumentText size={35} color="white" />
          </div>
          <div className="info">
            <p className="title">Entregable 1: Sap Logistica</p>
            <p className="publicado">Publicado el 15 Oct. 2021</p>
          </div>
        </div>
      </div> */}
    </div>
  );
  const dispatch = useDispatch();
  const createPresentation = async () => {
    var data = {
      student_id: course.student_id,
      project_id: project.id
    };
    var presentations = await CoursesService.createPresentation({ ...data });
    dispatch(
      updatePresentations({
        course_id: course.id,
        project_id: project.id,
        presentations: presentations?.data
      })
    );
    // console.log(presentations);
  };

  return (
    <Collapse className="collapse_container">
      <Panel
        className="panels"
        header={Content}
        // key="1"
        showArrow={true}
        onChange={(ev) => console.log(ev)}>
        <div className="body">
          <div className="project_description">
            {project?.description && (
              <div>
                <label>Descripción:</label>
                <p>{project?.description}</p>
              </div>
            )}
            {project?.attachments?.length > 0 && (
              <>
                <label>Adjuntos:</label>
                <div className="attachments">
                  {_.map(project?.attachments, (att, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        window.open(att.download_link, '_blank', 'noopener,noreferrer');
                      }}>
                      <div>
                        {att?.type == 'PDF' && (
                          <div className="attachment">
                            <BsFillFileEarmarkPdfFill size={30} />
                            <span>{att.name}</span>
                          </div>
                        )}
                        {att?.type == 'SLIDE' && (
                          <div className="attachment">
                            <BsFileEarmarkPptFill size={30} />
                            <span>{att.name}</span>
                          </div>
                        )}
                        {att?.type == 'EXCEL' && (
                          <div className="attachment">
                            <BsFileEarmarkExcel size={30} />
                            <span>{att.name}</span>
                          </div>
                        )}
                        {att?.type == 'WORD' && (
                          <div className="attachment">
                            <BsFileEarmarkWordFill size={30} />
                            <span>{att.name}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="project_presentation">
            {project?.my_presentations.length == 0 && (
              <button onClick={createPresentation}>Subir Archivo</button>
            )}

            {_.map(project?.my_presentations, (presentation, idx) => (
              <BOPresentation projectId={project.id} presentation={presentation} key={idx} />
            ))}
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};
BOProject.propTypes = {
  project: PropTypes.object,
  course: PropTypes.object
};
export default BOProject;
