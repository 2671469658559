import React from 'react';
// import { IoMdUnlock } from 'react-icons/io';
import {
  BsCameraVideoFill,
  BsFillBookFill,
  BsFillEyeSlashFill,
  BsDoorClosedFill
} from 'react-icons/bs';
import PropTypes from 'prop-types';
import './index.scss';
import Moment from 'react-moment';
import 'moment/locale/es';
const LiveSessions = ({ session }) => {
  // console.log(session);
  // console.log(new Date(session.start_datetime));
  const options = () => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
        {buttonMaterialUrl()}
        {buttonProximamente()}
        {buttonMeetingUrl()}
        {buttonFinishedMeet()}
        {buttonNoAvailable()}
        {buttonRecordMeetUrl()}
      </div>
    );
  };

  const isAvailableUrlMeeting = () => {
    var now = new Date();
    var end_session = new Date(session.end_datetime);
    var before_24h_end_session = new Date(end_session.getTime() - 24 * 60 * 60 * 1000);
    return now >= before_24h_end_session && session.meeting_url != undefined;
  };

  const buttonMaterialUrl = () => {
    return (
      session.material_url && (
        <button
          className={`btn_go active`}
          onClick={() => window.open(session.material_url)}
          style={{ backgroundColor: '#011754' }}>
          Material
          <BsFillBookFill size={20} />
        </button>
      )
    );
  };

  const buttonProximamente = () => {
    return !isAvailableUrlMeeting() && <button className={`btn_go`}>Proximamente</button>;
  };

  const buttonMeetingUrl = () => {
    return (
      isAvailableUrlMeeting() && (
        <button className={`btn_go active`} onClick={() => window.open(session.meeting_url)}>
          Ir a clase
          <BsCameraVideoFill size={20} color={'white'} />
        </button>
      )
    );
  };

  const buttonFinishedMeet = () => {
    return (
      session.record_meeting_url == '' &&
      session.meeting_url != '' &&
      new Date(session.end_datetime) < new Date() && (
        <button className={`btn_go`}>
          <span>Finalizado</span>
          <BsDoorClosedFill size={20} color={'white'} />
        </button>
      )
    );
  };

  const buttonNoAvailable = () => {
    return (
      session.meeting_url == '' && (
        <button className={`btn_go`}>
          No Disponible
          <BsFillEyeSlashFill size={20} color={'white'} />
        </button>
      )
    );
  };

  const buttonRecordMeetUrl = () => {
    return (
      session.record_meeting_url &&
      new Date(session.end_datetime) < new Date() && (
        <button className={`btn_go active`} onClick={() => window.open(session.record_meeting_url)}>
          Grabación
          <BsCameraVideoFill size={20} color={'white'} />
        </button>
      )
    );
  };

  return (
    <div className="live_sessions_container">
      <div className="left">
        <div className="info">
          <p className="title">{session.name}</p>
          <p>
            <Moment format="DD/MM/YYYY">{session.start_datetime}</Moment>
          </p>
          <p>
            De <Moment format="LT">{session.start_datetime}</Moment> a{' '}
            <Moment format="LT">{session.end_datetime}</Moment>
          </p>
          <p>
            <Moment fromNow>{session.start_datetime}</Moment>
          </p>
        </div>
      </div>
      <div className="right">{options()}</div>
    </div>
  );
};

LiveSessions.propTypes = {
  session: PropTypes.object
};

export default LiveSessions;
