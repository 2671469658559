/* eslint-disable react/prop-types */
import React from 'react';

import './index.scss';

const BOInput = ({ icon, label, type, placeholder, value, className, ...props }) => {
  return (
    <div className={`boinput_container bg-color-areas border-radius-20 ${className}`} {...props}>
      {label && <label>{label}</label>}
      <div className="input_icon">
        {icon && <div className="icon">{icon}</div>}
        <input
          className="input"
          type={type}
          placeholder={placeholder}
          value={value}
          {...props}></input>
      </div>
    </div>
  );
};

export default BOInput;
