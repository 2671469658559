import React from 'react';
import { useLocation } from 'react-router-dom';
import CourseSesiones from '../../../../components/organisms/CourseGrabadas/CourseSesiones';

const SesionsPage = () => {
  const location = useLocation();

  // console.log('Location', location);

  return <CourseSesiones lectures={location?.state?.lectures} />;
};

export default SesionsPage;
