/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';
import _ from 'lodash';
import { BsCloudDownloadFill } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ICON_SIZE = 30;
const TAB_ZOOM_CERTITICATES = 1;
const TAB_ONLINE_CERTITICATES = 2;
const ZOOM_CERTIFICATES_SECTION = [
  ['Certificados de Diplomados', 'zoom_dip'],
  ['Certificados de Especialización', 'zoom_esp'],
  ['Certificados de PEE', 'zoom_pee'],
  ['Certificados Individuales', 'zoom_ind']
];
const ONLINE_CERTIFICATES_SECTION = [
  ['Certificados de Especialización', 'online_esp'],
  ['Certificados Individuales', 'online_ind']
];

const CertificatesPage = () => {
  const certificates = useSelector((state) => state.certificatesSlice?.value?.data) || [];
  const [tab, setTab] = useState(TAB_ZOOM_CERTITICATES);
  const [certificates_section, set_certificates_section] = useState(ZOOM_CERTIFICATES_SECTION);
  const [total_certificates_zoom, set_total_certificates_zoom] = useState(0);
  const [total_certificates_online, set_total_certificates_online] = useState(0);

  useEffect(() => {
    let certificates_zoom = _.filter(certificates, (cert) => cert.course_type.indexOf('zoom') >= 0);
    set_total_certificates_zoom(certificates_zoom.length);

    let certificates_online = _.filter(
      certificates,
      (cert) => cert.course_type.indexOf('online') >= 0
    );
    console.log(certificates);
    set_total_certificates_online(certificates_online.length);
  }, [certificates]);

  const block_certificates_by_course_type = (title, certificates) => {
    return (
      <div key={title}>
        {certificates.length > 0 && (
          <>
            <h2>{title}</h2>
            <div className="certificate_container_flex">
              <div className="certificate_desktop">
                <div className="certificate_grid">
                  {_.map(certificates, (cert, idx) => (
                    <div className="certificate_body" key={idx}>
                      <div className="image_title">
                        <LazyLoadImage src={cert.url_img_certificate_file} height={400} />
                        <span className="course_name">{cert.course_name}</span>
                      </div>
                      <div className="btn-download">
                        <BsCloudDownloadFill
                          size={ICON_SIZE}
                          onClick={() =>
                            window.open(
                              cert.url_pdf_certificate_file,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const change_certificates_section = (tab, certificate_section) => {
    setTab(tab);
    set_certificates_section(certificate_section);
  };

  return (
    <>
      <div className="certificates_container">
        <h1>Mis Certificados</h1>
        <div className="tab_certificates_section">
          <button
            className={`bo_button border-radius-30  ${
              TAB_ZOOM_CERTITICATES == tab ? 'bg-color-secondary active' : ''
            }`}
            onClick={() => {
              change_certificates_section(TAB_ZOOM_CERTITICATES, ZOOM_CERTIFICATES_SECTION);
            }}>
            <span>Cursos Zoom</span>
          </button>
          <button
            className={`bo_button border-radius-30 ${
              TAB_ONLINE_CERTITICATES == tab ? 'bg-color-secondary active' : ''
            }`}
            onClick={() =>
              change_certificates_section(TAB_ONLINE_CERTITICATES, ONLINE_CERTIFICATES_SECTION)
            }>
            <span>Cursos Online</span>
          </button>
        </div>
        <br />
        <br />
        {certificates_section.map((cert) => {
          return (
            <div key={cert[0]}>
              {block_certificates_by_course_type(
                cert[0],
                _.filter(certificates, (el) => el.course_type == cert[1])
              )}
            </div>
          );
        })}
        {total_certificates_zoom == 0 && tab == TAB_ZOOM_CERTITICATES && (
          <div className="certificate_without_results">Aún no tienes certificados.</div>
        )}

        {total_certificates_online == 0 && tab == TAB_ONLINE_CERTITICATES && (
          <div className="certificate_without_results">Aún no tienes certificados.</div>
        )}
      </div>
    </>
  );
};

export default CertificatesPage;
